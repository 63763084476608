import * as React from "react";
import { Link } from "gatsby";

import Bridge from "../../components/Bridge.js";
import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
// import PreChorus from "../../components/PreChorus.js";
import Verse from "../../components/Verse.js";

export default function WhereDoesThatLeaveUs() {
  return (
    <Layout>
      <Helmet title="Where Does That Leave Us | Michael Weslander" />

      <h1>Where Does That Leave Us</h1>
      <h2>Michael Weslander</h2>
      <h3>Key: E</h3>
      <h4>Tempo: 88</h4>
      <Link to="/">List</Link>

      <Columns columns="2">
        <Verse>
          <p>
            <Ch>E</Ch>The queen of diamonds
          </p>
          <p>Playing games with a joker</p>
          <p>Alone on some Saturday</p>
          <p>Just enough to hold her over</p>
          <p>
            <Ch>A</Ch>Her hands are getting cold
          </p>
          <p>Will she fold or maybe play</p>
          <p>
            Along the river<Ch>E</Ch>
          </p>
        </Verse>

        <Chorus>
          <p>
            <Ch>G#m</Ch>I don't want to know
          </p>
          <p>
            <Ch>F#m</Ch>Even if she'd show me
          </p>
          <p>
            Where does that <Ch>E</Ch>leave us?
          </p>
        </Chorus>

        <Verse>
          <p>
            <Ch>E</Ch>Maybe one more week
          </p>
          <p>Living in limbo</p>
          <p>No fifty shades of gray</p>
          <p>Could stop this as it frays</p>
          <p>
            <Ch>A</Ch>The hands I used to hold
          </p>
          <p>Left me in the cold</p>
          <p>Debating our gamble</p>
        </Verse>

        <Chorus>
          <p>
            <Ch>G#m</Ch>I don't want to go, but
          </p>
          <p>
            <Ch>F#m</Ch>There's nothing here to grow
          </p>
          <p>
            Where does that <Ch>E</Ch>leave us?
          </p>
        </Chorus>

        <Bridge>
          <p>
            <Ch>F#m</Ch>New shoes, new tastes
          </p>
          <p>
            <Ch>G#m</Ch>New walk, new grace
          </p>
          <p>
            <Ch>A</Ch>New style, new fade
          </p>
          <p>
            <Ch>B</Ch>New job, getting paid
          </p>
          <p>
            <Ch>C#m</Ch>Same old haze of never
          </p>
          <p>
            <Ch>D</Ch>knowing
          </p>
          <p>
            <Ch>A</Ch>Never confronting
          </p>
          <p>
            <Ch>B</Ch>The strain
          </p>
        </Bridge>

        <Verse>E A E</Verse>

        <Chorus>
          <p>
            <Ch>G#m</Ch>I don't want to fold, if
          </p>
          <p>
            <Ch>F#m</Ch>There's something down the road here
          </p>
          <p>
            Where does that <Ch>E</Ch>leave us?
          </p>
        </Chorus>

        <Chorus>
          <p>
            <Ch>G#m</Ch>I don't want to stay
          </p>
          <p>
            <Ch>F#m</Ch>Only in the gray
          </p>
          <p>
            Where does that <Ch>E</Ch>leave us?
          </p>
        </Chorus>

        <Chorus>
          <p>
            <Ch>G#m</Ch>If only she would stay
          </p>
          <p>
            <Ch>F#m</Ch>She just wants to stay
          </p>
          <p>
            Where does that <Ch>E</Ch>leave us?
          </p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
